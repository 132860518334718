import Devices from "./devices";
import EpDevice from "./ep-device";
import DeviceType from "./device-type";
import { filterAdvRuleTemplatesByEpDevice } from "./rule-filters";
import Constants from "./constants";
import ClusterConstants from "./cluster-constants";
// types
import type { EmptyObject, ReadonlyDeep, ValueOf } from "type-fest";
import type { GatewayId } from "../types/gateway";
import type { DeviceId, EndpointId, DeviceName, Cap, DeviceData, DeviceObj, EpDevices, Endpoints, Endpoint } from "../types/device";
import type { Clusters, Cluster, ClusterByCapAndClusterId, IncapsClusterFF80, ClusterId } from "../types/cluster";
import type { DeviceTypes, DeviceType as DeviceTypeT } from "../types/device-type";
import type { DeviceIcons, RocId, RocIdData, FilteredAdvancedRulesTableByType } from "../types/roc-table";
import type { AdvancedParamCategory, RuleItemsType } from "../types/rule";
import type { PayloadBroadcastAttributeReport, ChildDocuments } from "../types/message";
import type { SetpointOptions } from "../types/misc";

type SetpointMode = ValueOf<typeof ClusterConstants.DFF80.CmdPayloads>;

export const getClusterByClusterId = <CID extends ClusterId = ClusterId>(clusters: ReadonlyDeep<Clusters>, clusterId: CID): ClusterByCapAndClusterId<Cap, CID> | undefined => (
	clusters.find((cluster) => (cluster.cluster_id === clusterId))
);

export const getClusterFromEndpoint = <CAP extends Cap = Cap, CID extends ClusterId<CAP> = ClusterId<CAP>>(endpoint: ReadonlyDeep<Endpoint>, cap: CAP, clusterId: CID): ClusterByCapAndClusterId<CAP, CID> | undefined => {
	const clusters = endpoint[cap]; // TODO: clusters never undefined?
	return clusters ? getClusterByClusterId(clusters, clusterId) : undefined;
};

export const hasEndpointCapClusterId = <CAP extends Cap = Cap, CID extends ClusterId<CAP> = ClusterId<CAP>>(endpoint: ReadonlyDeep<Endpoint>, cap: CAP, clusterId: CID): boolean => {
	const clusters = endpoint[cap]; // TODO: clusters never undefined?
	return clusters ? clusters.some((cluster) => (cluster.cluster_id === clusterId)) : false;
};

export const hasEndpointClusterId = (endpoint: ReadonlyDeep<Endpoint>, clusterId: ClusterId): boolean => (
	Object.values(Constants.Caps).some((cap) => (
		hasEndpointCapClusterId(endpoint, cap, clusterId)
	))
);

export const getCapByClusterId = (clusterId: ClusterId): Cap => (
	DeviceType[`D${clusterId}`].cap
);

export const haveEndpointsClusterIdByDeviceTypeCap = (endpoints: ReadonlyDeep<Endpoints>, clusterId: ClusterId): boolean => {
	const cap = getCapByClusterId(clusterId);
	return endpoints.some((endpoint) => (
		hasEndpointCapClusterId(endpoint, cap, clusterId)
	));
};

export const getDeviceTypeForClusterIds = <CIDS extends ReadonlyArray<ClusterId> = ReadonlyArray<ClusterId>>(endpoints: ReadonlyDeep<Endpoints>, clusterIdOrder: CIDS): DeviceTypeT<CIDS[number]> | undefined => {
	const clusterId = clusterIdOrder.find((clusterId) => (haveEndpointsClusterIdByDeviceTypeCap(endpoints, clusterId)));
	return clusterId ? DeviceType[`D${clusterId}`] : undefined;
};

export const getDeviceIcons = (device: DeviceObj): DeviceIcons => {
	const clusterIdOrder = [
		DeviceType.DC38A.clusterId,
		DeviceType.D0006.clusterId,
		DeviceType.D0101.clusterId,
		DeviceType.D0102.clusterId,
		DeviceType.D0500.clusterId,
		DeviceType.DFF84.clusterId,
		DeviceType.DFFAC.clusterId,
		DeviceType.DFC00.clusterId,
	] as const satisfies ReadonlyArray<ClusterId>;
	const deviceType = getDeviceTypeForClusterIds(device.eps, clusterIdOrder);
	return deviceType ? deviceType.getDeviceIconsFromDevice(device) : [device.getRocIdData("icon")] as const;
};

export const getStaticCluster = (rocIdData: RocIdData, attr: ReadonlyDeep<PayloadBroadcastAttributeReport>): ReadonlyDeep<Cluster> | EmptyObject => {
	if (rocIdData.staticEndpointData) {
		const endpoint = (rocIdData.staticEndpointData as ReadonlyDeep<Endpoints>).find((endpoint) => (endpoint.endpoint === attr.endpoint));
		if (endpoint) {
			const cluster = getClusterFromEndpoint(endpoint, attr.caps ?? Constants.Caps.Incaps, attr.cluster_id);
			if (cluster) {
				return structuredClone(cluster);
			}
		}
	} else {
		const device = Devices.getDeviceById(attr.deviceId);
		if (device) {
			const cluster = device.getClusterByEpIdAndCapAndClusterId(attr.endpoint, attr.caps, attr.cluster_id);
			if (cluster) {
				return structuredClone(cluster);
			}
		}
	}
	return {};
};

export const getDeviceTypesForEndpoint = (endpoint: ReadonlyDeep<Endpoint>): DeviceTypes => {
	const deviceTypes: DeviceTypes = [];

	for (const cluster of endpoint[Constants.Caps.Incaps]) {
		const deviceType = DeviceType[`D${cluster.cluster_id}`] as DeviceTypeT<typeof cluster.cluster_id> | undefined;
		if (deviceType && (!("ignore" in deviceType) || !deviceType.ignore)) {
			deviceTypes.push(deviceType);
		}
	}
	const clusterDC38A = getClusterFromEndpoint(endpoint, DeviceType.DC38A.cap, DeviceType.DC38A.clusterId);
	if (clusterDC38A) {
		deviceTypes.push(DeviceType.DC38A);
	}
	const clusterDC38E = getClusterFromEndpoint(endpoint, DeviceType.DC38E.cap, DeviceType.DC38E.clusterId);
	if (clusterDC38E) {
		deviceTypes.push(DeviceType.DC38E);
	}

	return deviceTypes;
};

export const getShowableEpDevicesFromDevice = (device: DeviceObj): EpDevices => (
	Devices.getEpDevicesFromDevices([device], (endpoint, device) => (
		getDeviceTypesForEndpoint(endpoint).length > 0 || Boolean(device.getRocIdData("is_group"))
	))
);

export const updateObjectWithChildDocuments = (object: object, childDocuments: ReadonlyDeep<ChildDocuments>) => {
	for (const childDocument of childDocuments) {
		const value = childDocument[childDocument.val];
		if (!Object.hasOwn(object, childDocument.id) || value !== null) {
			object[childDocument.id] = value;
		}
	}
};

export const getSetpointOptions = (cluster: IncapsClusterFF80, mode: SetpointMode = ClusterConstants.DFF80.CmdPayloads.SetModeHeat): SetpointOptions => {
	const setpointOptions = {
		min: 10,
		max: 30,
		step: 0.5,
	} satisfies SetpointOptions;
	const clusterKeys = {
		min: "",
		max: "",
		step: "",
	};
	switch (mode) {
		case ClusterConstants.DFF80.CmdPayloads.SetModeHeat:
			clusterKeys.min = ClusterConstants.DFF80.Attributes.MinHeat;
			clusterKeys.max = ClusterConstants.DFF80.Attributes.MaxHeat;
			clusterKeys.step = ClusterConstants.DFF80.Attributes.StepSizeHeat;
			break;
		case ClusterConstants.DFF80.CmdPayloads.SetModeCool:
			clusterKeys.min = ClusterConstants.DFF80.Attributes.MinCool;
			clusterKeys.max = ClusterConstants.DFF80.Attributes.MaxCool;
			clusterKeys.step = ClusterConstants.DFF80.Attributes.StepSizeCool;
			break;
		default:
			return setpointOptions;
	}
	if (typeof cluster[clusterKeys.min] === "number") {
		setpointOptions.min = cluster[clusterKeys.min] / 100;
	}
	if (typeof cluster[clusterKeys.max] === "number") {
		setpointOptions.max = cluster[clusterKeys.max] / 100;
	}
	if (typeof cluster[clusterKeys.step] === "number") {
		setpointOptions.step = cluster[clusterKeys.step] / 100;
	}
	return setpointOptions;
};

export const getDeviceIconsFromStatus = (status: boolean, rocIdData: RocIdData): DeviceIcons => {
	if (status) {
		if (rocIdData.icon_blink) {
			return [rocIdData.icon_a, rocIdData.icon_b] as const satisfies DeviceIcons;
		} else {
			return [rocIdData.icon_a] as const satisfies DeviceIcons;
		}
	} else {
		return [rocIdData.icon_b] as const satisfies DeviceIcons;
	}
};

export const getFilteredEpDevices = <RIT extends RuleItemsType = RuleItemsType>(preFilteredEpDevices: EpDevices, preFilteredRulesTable: FilteredAdvancedRulesTableByType<RIT>, itemsType: RIT, advancedParamCategory: AdvancedParamCategory) => {
	const filteredEpDevices = preFilteredEpDevices.filter((epDevice) => (
		filterAdvRuleTemplatesByEpDevice(preFilteredRulesTable, epDevice, itemsType, advancedParamCategory).length > 0
	));
	return filteredEpDevices.map((epDevice) => {
		epDevice.multiEps = filteredEpDevices.filter((epDev) => (epDev.id === epDevice.id)).length > 1;
		return epDevice;
	});
};

const getUnknownEpDevice = (deviceId: DeviceId, endpointId: EndpointId) => {
	const deviceData = {
		srcGw: "",
		id: deviceId,
		mac: deviceId,
		name: deviceId,
		rocId: "",
		endpoints: [{
			endpoint: endpointId,
			appID: "",
			appVer: "",
		}],
		attributes: {
			[Constants.Device.Attributes.Property.PowerSource]: Constants.Device.Attributes.Value.PowerSource.Unknown,
			[Constants.Device.Attributes.Property.DeviceType]: "",
		},
		profileId: "",
		FF01: Constants.Device.Value.ReachableStatus.Unreachable,
	} as const satisfies ReadonlyDeep<DeviceData>;
	const epDevice = new EpDevice("", deviceData, endpointId);
	epDevice.epDeviceType = Constants.EpDeviceType.Device;

	return epDevice;
};

export const getEpDeviceByFilter = (epDevices: EpDevices, deviceId: DeviceId | undefined, endpoint: EndpointId | undefined, clusterId: ClusterId | "0000" | undefined) => {
	const epDevice = epDevices.find((epDevice) => (
		epDevice.id === deviceId && (!endpoint || epDevice.epId === endpoint || (endpoint === "00" && clusterId === "0000"))
	))?.clone();

	if (epDevice) {
		if (endpoint === "00" && clusterId === "0000") {
			epDevice.multiEps = false;
			epDevice.epDeviceType = Constants.EpDeviceType.Device;
		} else {
			epDevice.epDeviceType = Constants.EpDeviceType.Endpoint;
		}
		return epDevice;
	}

	return getUnknownEpDevice(deviceId ?? "", endpoint ?? "");
};

export const getFakeAllPhonesEpDevice = (gatewayId: GatewayId, deviceId: DeviceId, deviceName: DeviceName) => {
	const endpointId = "01" as EndpointId;
	const deviceData = {
		srcGw: gatewayId,
		id: deviceId,
		mac: deviceId,
		name: deviceName,
		rocId: "ROC0005" as RocId,
		endpoints: [{
			endpoint: endpointId,
			appID: "",
			appVer: "",
			incaps: [{
				"cluster_id": "FFAD",
				"0000": false,
				"0001": 0,
				"0002": 0,
				"0003": null,
			}],
		}],
		attributes: {
			[Constants.Device.Attributes.Property.PowerSource]: Constants.Device.Attributes.Value.PowerSource.Unknown,
			[Constants.Device.Attributes.Property.DeviceType]: Constants.Device.Attributes.Value.DeviceType.GeofenceActions,
		},
		profileId: "",
		FF01: Constants.Device.Value.ReachableStatus.Reachable,
	} as const satisfies ReadonlyDeep<DeviceData>;
	const epDevice = new EpDevice(gatewayId, deviceData, endpointId);
	epDevice.epDeviceType = Constants.EpDeviceType.Device;

	return epDevice;
};
