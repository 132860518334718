import { EventEmitter } from "events";
import { Capacitor } from "@capacitor/core";
// hooks
import { filterRules } from "../hooks/useRules";
// services
import i18n from "./i18n";
import Glient from "./glient";
import User from "./user";
import Gateways from "./gateways";
import Gateway from "./gateway";
import RocTable from "./roc-table";
import Device from "./device";
import EpDevice from "./ep-device";
import Rules from "./rules";
import { getFilterTemplateRules } from "./rule-filters";
import RuleCategory from "./rule-category";
import Constants from "./constants";
import ClusterConstants from "./cluster-constants";
import { mapOrArrayValues } from "./utils";
import { StorageKeys, Storage } from "./storage";
// types
import type { ReadonlyDeep, SetOptional } from "type-fest";
import type { TemplateRulesTable } from "../types/roc-table";
import type { GatewayId, GatewayStatus } from "../types/gateway";
import type { DeviceId, DeviceObjs, DeviceObj, DevicesData, Endpoint, EpDevices } from "../types/device";
import type { DeviceType } from "../types/device-type";
import type { TemplateRule, TemplateChecksOrActions } from "../types/rule";
import type { HandlerId } from "../types/roc-ws";
import type {
	CmdGatewayActionGatewayDefault, CmdSendActionCmd,
	MsgBroadcastAttributeReport, MsgBroadcastDeleteDevice, MsgBroadcastDeviceReport,
} from "../types/message";
import type { GuestPasswords, GuestPassword, GuestPasswordId } from "../types/misc";

type CmdId = typeof ClusterConstants.DC38A.CmdIds.RTSPStream | typeof ClusterConstants.DC38A.CmdIds.MJPEGStream | typeof ClusterConstants.DC38A.CmdIds.HLSStream | typeof ClusterConstants.DC38A.CmdIds.WebRTCStream;
type GetCameraStreamURLCallback = (error: Error | null, url: string | undefined) => void;
type DevicesCallback = (errors: Array<Error> | null, devices: DeviceObjs) => void;
type GuestPwdListDevicesCallback = (error: Error | null, passwordList: ReadonlyDeep<GuestPasswords>) => void;
type FilterFunc = (endpoint: ReadonlyDeep<Endpoint>, device: DeviceObj) => boolean;

export const fetchDevicesFromGateway = async (gatewayId: GatewayId): Promise<ReadonlyDeep<DevicesData>> => (
	new Promise((resolve, reject) => {
		const cmd = {
			action: "gatewayAction",
			module: "gateway",
			function: "getDevicesReports",
			params: [],
			gatewayId: gatewayId,
		} as const satisfies CmdGatewayActionGatewayDefault;
		Glient.send(cmd, (error, msg) => {
			if (!error && msg?.payload.status === "ok") {
				resolve(msg.payload.data as ReadonlyDeep<DevicesData>);
			} else {
				reject(error);
			}
		});
	})
);

/**
 * A in-memory storage for devices of a gateway. Once the devices are loaded,
 * they are updated as soon as the "attributeReport" broadcasts are received.
 * So, the devices are loaded and kept in memory and updated on broadcasts.
 * That's how we have the latest state of a device.
 * @see message-handler.js
 * @class Devices
 * @extends EventEmitter
 */
class Devices extends EventEmitter {

	#loaded = false;
	#fetchedAt = 0;
	#gatewayId: GatewayId | null = null;
	#devices = new Map<DeviceId, DeviceObj>();
	#passwordList: GuestPasswords = [];

	constructor() {
		super();

		this.setMaxListeners(256); // a listener per device in the devices view

		this.handleSelectedGatewayChanged = this.handleSelectedGatewayChanged.bind(this);
		this.handleGatewayStatusChanged = this.handleGatewayStatusChanged.bind(this);

		Gateway.on("selectedGatewayChanged", this.handleSelectedGatewayChanged);
		Gateway.on("statusChanged", this.handleGatewayStatusChanged);
	}

	/**
	 * On gateway changed, devices are fetched for the selected gateway.
	 */
	private handleSelectedGatewayChanged(/*gateway*/): void {
		this.#loaded = false;
		// this.#fetchDevices(() => {});
	}

	private handleGatewayStatusChanged(status: GatewayStatus | undefined): void {
		if (status === Constants.Gateway.Status.Unreachable) {
			this.#loaded = false;
			this.#fetchedAt = 0;
			this.#devices = new Map();
			this.#passwordList = [];
			this.emit("reset");
			this.emit("changed", []);
		}
	}

	public reloadDevices(): void {
		this.#loaded = false;
		this.#fetchDevices(() => {});
	}

	public isLoaded(): boolean {
		return this.#loaded;
	}

	/**
	 * @returns {Object[]} List of devices.
	 */
	public get(): DeviceObjs {
		return Array.from(this.#devices.values());
	}

	public getDeviceById(deviceId: DeviceId): DeviceObj | undefined {
		return this.#devices.get(deviceId);
	}

	/**
	 * Fetches the devices from server and assign the category.
	 * @param {Function} callback
	 */
	public getDevices(callback: DevicesCallback): void {
		if (Gateways.loaded) {
			this.#fetchDevices(callback);
		} else {
			Gateways.once("gatewaysChanged", () => {
				this.#fetchDevices(callback);
			});
		}
	}

	/**
	 * Fetches the device from server.
	 * TODO: make async
	 * @param {Function} callback
	 */
	#fetchDevices(callback: DevicesCallback): void {
		const selectedGatewayId = Gateway.selectedGatewayId;
		if (selectedGatewayId === undefined || Gateway.getMessage() !== null) {
			const errors = [new Error("No Gateway selected or unreachable")];
			callback(errors, Array.from(this.#devices.values()));
		} else if (this.#loaded && this.#gatewayId === selectedGatewayId && User.loggedInAt <= this.#fetchedAt) {
			callback(null, Array.from(this.#devices.values()));
		} else {
			const gatewayIds = Gateways.getGatewayIdsOffVisibleDevices();

			const tasks = gatewayIds.map((gatewayId) => (fetchDevicesFromGateway(gatewayId)));

			void Promise.allSettled(tasks).then((results) => {
				const errors = results.filter((result) => (result.status === "rejected")).map((result) => (result.reason));
				if (errors.length > 0) {
					console.warn("Fetch devices failed:", errors);
				}
				const _devices = results
					.filter((result) => (result.status === "fulfilled"))
					.flatMap((result) => (result.value))
					.map((deviceData) => (new Device(selectedGatewayId, deviceData)));
				this.#devices = new Map(_devices.map((device) => [device.id, device]));
				this.#loaded = true;
				this.#fetchedAt = Date.now();
				this.#gatewayId = selectedGatewayId;

				this.#addMissingDevicesToRoomFavoritesStorage();
				// this.#cleanStorageFromUnavailableDevices(); // TODO: reenable workaround in future. see: OS-4386

				const devices = Array.from(this.#devices.values());
				callback((errors.length === 0) ? null : errors, devices);
				this.emit("loaded", devices);
				// this.emit("changed", devices);
			});
		}
	}

	#addMissingDevicesToRoomFavoritesStorage(): void {
		const gateway = Gateway.selectedGateway;
		if (gateway) {
			const favoriteRoomsOrder = Storage.get(StorageKeys.favoriteRoomsOrder, { GWID: gateway.id }, { GWID: gateway.srcGw }) ?? [];
			for (const favoriteRoomId of favoriteRoomsOrder) {
				const favoriteRoomDevicesOrder = Storage.get(StorageKeys.favoriteRoomDevicesOrder, { GWID: gateway.id, ROOM_ID: favoriteRoomId }, { GWID: gateway.srcGw, ROOM_ID: favoriteRoomId }) ?? [];
				const missingRoomDeviceIds = Array.from(this.#devices.values()).filter((device) => (
					device.eps.some((ep) => (ep.room_id === favoriteRoomId)) && !favoriteRoomDevicesOrder.includes(device.id)
				)).map((device) => (device.id));
				const newFavoriteRoomDevicesOrder = [...favoriteRoomDevicesOrder, ...missingRoomDeviceIds];
				Storage.set(StorageKeys.favoriteRoomDevicesOrder, newFavoriteRoomDevicesOrder, { GWID: gateway.id, ROOM_ID: favoriteRoomId });
			}
		}
	}

	// TODO: reenable workaround in future. see: OS-4386
	// #cleanStorageFromUnavailableDevices(): void {
	// 	const gateway = Gateway.selectedGateway;
	// 	if (gateway) {
	// 		const favoriteDevices = Storage.get(StorageKeys.favoriteDeviceIds, { GWID: gateway.id }, { GWID: gateway.srcGw });
	// 		const invalidFavoriteDeviceIds = favoriteDevices
	// 			.filter((favoriteDevice) => (!this.#devices.has(favoriteDevice.id)))
	// 			.map((invalidFavoriteDevice) => (invalidFavoriteDevice.id));
	// 		if (invalidFavoriteDeviceIds.length > 0) {
	// 			const newFavoriteDevices = favoriteDevices.filter((favoriteDevice) => (!invalidFavoriteDeviceIds.includes(favoriteDevice.id)));
	// 			Storage.set(StorageKeys.favoriteDeviceIds, newFavoriteDevices, { GWID: gateway.id });
	// 		}

	// 		const favoriteRoomsOrder = Storage.get(StorageKeys.favoriteRoomsOrder, { GWID: gateway.id }, { GWID: gateway.srcGw }) ?? [];
	// 		for (const favoriteRoomId of favoriteRoomsOrder) {
	// 			const favoriteRoomDevicesOrder = Storage.get(StorageKeys.favoriteRoomDevicesOrder, { GWID: gateway.id, ROOM_ID: favoriteRoomId }, { GWID: gateway.srcGw, ROOM_ID: favoriteRoomId });
	// 			if (favoriteRoomDevicesOrder?.some((favoriteRoomDeviceOrder) => (!this.#devices.has(favoriteRoomDeviceOrder)))) {
	// 				const newFavoriteRoomDevicesOrder = favoriteRoomDevicesOrder.filter((favoriteRoomDeviceOrder) => (this.#devices.has(favoriteRoomDeviceOrder)));
	// 				Storage.set(StorageKeys.favoriteRoomDevicesOrder, newFavoriteRoomDevicesOrder, { GWID: gateway.id, ROOM_ID: favoriteRoomId });
	// 			}
	// 		}

	// 		const accountDeviceId = Storage.get(StorageKeys.accountDeviceId);
	// 		if (accountDeviceId && !this.#devices.has(accountDeviceId)) {
	// 			Storage.remove(StorageKeys.accountDeviceId);
	// 		}
	// 	}
	// }

	/**
	 * On receiving a addDevice broadcast, adds the device to store and fires a addDevice event.
	 * @param {Object} msg
	 * @event addDevice
	 * @see message-handler.js
	 */
	public handleDeviceReportBroadcast(msg: MsgBroadcastDeviceReport): void {
		const device = this.#devices.get(msg.payload.data.id);
		if (device) {
			device.updateDeviceDataFull(msg.payload);
			Gateway.updateJoiningDevicesByDevice(device);
			this.emit("updated", device);
		} else {
			const newDevice = new Device(msg.payload.gatewayId, msg.payload.data);
			this.#devices.set(newDevice.id, newDevice);
			Gateway.updateJoiningDevicesByDevice(newDevice);
			this.#deviceAdded(newDevice);
			this.emit("added", newDevice);
		}
		this.emit("changed", Array.from(this.#devices.values()));
	}

	/**
	 * Updates the device when a broadcast is received.
	 * @param {Object} msg
	 * @see message-handler.js
	 */
	public handleAttributeReportBroadcast(msg: MsgBroadcastAttributeReport): void {
		const device = this.#devices.get(msg.payload.deviceId);

		if (device) {
			device.updateDeviceData(msg.payload);
			Gateway.updateJoiningDevicesByDevice(device);
			this.emit("updated", device);
			this.emit("attributeReport", msg, device);
			this.emit("changed", Array.from(this.#devices.values()));
		}
	}

	/**
	 * Deletes a device.
	 * @param {String} deviceId
	 * @returns {Object} deleted device.
	 */
	public deleteDevice(deviceId: DeviceId): DeviceObj | null | undefined {
		if (this.#devices.size > 0 && this.#devices.has(deviceId)) {
			const device = this.#devices.get(deviceId);
			this.#devices.delete(deviceId);
			return device;
		}
		return null;
	}

	/**
	 * Fetches guest password list for a device.
	 * @param {Object} cmd
	 * @param {Function} callback
	 */
	public loadGuestPwdList(cmd: CmdSendActionCmd, callback: GuestPwdListDevicesCallback) {
		Glient.send(cmd, (error, msg) => {
			this.#passwordList = msg.payload.data;
			callback(error, msg.payload.data);
		});
	}

	public getPasswordById(passwordId: GuestPasswordId): GuestPassword | undefined {
		return this.#passwordList.find((password) => (password.id === passwordId));
	}

	// TODO: reenable workaround in future. see: OS-4386
	// #cleanStorageFromRemovedDevice(device: DeviceObj): void {
	// 	const gateway = Gateway.selectedGateway;
	// 	if (gateway) {
	// 		const favoriteDevices = Storage.get(StorageKeys.favoriteDeviceIds, { GWID: gateway.id }, { GWID: gateway.srcGw });
	// 		if (favoriteDevices.some((favoriteDevice) => (favoriteDevice.id === device.id))) {
	// 			const newFavoriteDevices = favoriteDevices.filter((favoriteDevice) => (favoriteDevice.id !== device.id));
	// 			Storage.set(StorageKeys.favoriteDeviceIds, newFavoriteDevices, { GWID: gateway.id });
	// 		}

	// 		const favoriteRoomsOrder = Storage.get(StorageKeys.favoriteRoomsOrder, { GWID: gateway.id }, { GWID: gateway.srcGw }) ?? [];
	// 		for (const favoriteRoomId of favoriteRoomsOrder) {
	// 			const favoriteRoomDevicesOrder = Storage.get(StorageKeys.favoriteRoomDevicesOrder, { GWID: gateway.id, ROOM_ID: favoriteRoomId }, { GWID: gateway.srcGw, ROOM_ID: favoriteRoomId });
	// 			if (favoriteRoomDevicesOrder?.includes(device.id)) {
	// 				const newFavoriteRoomDevicesOrder = favoriteRoomDevicesOrder.filter((favoriteRoomDeviceId) => (favoriteRoomDeviceId !== device.id));
	// 				Storage.set(StorageKeys.favoriteRoomDevicesOrder, newFavoriteRoomDevicesOrder, { GWID: gateway.id, ROOM_ID: favoriteRoomId });
	// 			}
	// 		}

	// 		const accountDeviceId = Storage.get(StorageKeys.accountDeviceId);
	// 		if (accountDeviceId === device.id) {
	// 			Storage.remove(StorageKeys.accountDeviceId);
	// 		}
	// 	}
	// }

	/**
	 * On receiving a deleteDevice broadcast, deletes the device from store and fires a deleteDevice event.
	 * @param {Object} msg
	 * @event deleteDevice
	 * @see message-handler.js
	 */
	public handleDeleteDeviceBroadcast(msg: MsgBroadcastDeleteDevice): void {
		const device = this.deleteDevice(msg.payload.deviceId);
		if (device) {
			// this.#cleanStorageFromRemovedDevice(device); // TODO: reenable workaround in future. see: OS-4386
			this.emit("deleted", device);
			this.emit("changed", Array.from(this.#devices.values()));
		}
	}

	public getEpDevicesFromDevices(devices: DeviceObjs, filterFunc: FilterFunc = (/*endpoint: ReadonlyDeep<Endpoint>, device: DeviceObj*/): true => true): EpDevices { // TODO: make static
		return devices.map((device) => (
			device.eps
				.filter((endpoint) => (filterFunc(endpoint, device)))
				.map((endpoint) => (EpDevice.getEpDeviceFromDeviceAndEpId(device, endpoint.endpoint)))
		)).flatMap((epDevices) => (
			epDevices.map((epDevice) => {
				epDevice.multiEps = epDevices.length > 1;
				return epDevice;
			})
		));
	}

	public getCmdId(streamingCapabilities: number): CmdId | null {
		if (streamingCapabilities & Constants.StreamingCapabilities.WebRTC) {
			return ClusterConstants.DC38A.CmdIds.WebRTCStream;
		}
		if (streamingCapabilities & Constants.StreamingCapabilities.HLS) {
			return ClusterConstants.DC38A.CmdIds.HLSStream;
		}
		if (Capacitor.isNativePlatform() && (streamingCapabilities & Constants.StreamingCapabilities.RTSP)) {
			return ClusterConstants.DC38A.CmdIds.RTSPStream;
		}
		if (streamingCapabilities & Constants.StreamingCapabilities.MJPEG) {
			return ClusterConstants.DC38A.CmdIds.MJPEGStream;
		}

		return null;
	}

	public getStreamUrl(epDevice: EpDevice, deviceType: DeviceType<"C38A">, cmdId: CmdId, callback: GetCameraStreamURLCallback): HandlerId {
		const cmd = {
			action: "sendActionCmd",
			gatewayId: epDevice.gwId,
			srcGw: epDevice.srcGw,
			deviceId: epDevice.id,
			endpoint: epDevice.epId,
			caps: deviceType.cap,
			clusterId: deviceType.clusterId,
			cmdId: cmdId,
		} as const satisfies CmdSendActionCmd;
		return Glient.send(cmd, (error, msg) => {
			callback(error, msg?.payload.data);
		});
	}

	#deviceAdded(device: DeviceObj): void {
		const gateway = Gateway.selectedGateway;
		const isOwner = gateway ? Boolean(gateway.rbac & Constants.Gateway.RBAC.Owner) : false;
		const quickRuleIds = User.channelInfo?.quickRuleIds;

		if (gateway && isOwner && Array.isArray(quickRuleIds)) {
			Rules.getRules((error, rules) => {
				if (!error && rules) {
					const deviceTemplateRules = filterRules(rules, [Constants.Rule.Type.Template], device.id, undefined);
					if (deviceTemplateRules.length === 0) {
						RocTable.getGrRules((rulesTable) => {
							const filteredTemplateRulesTable = rulesTable.filter((rule) => (quickRuleIds.includes(rule.id)));
							this.#create(gateway.id, device, filteredTemplateRulesTable);
						});
					}
				}
			});
		}
	}

	#create(gatewayId: GatewayId, device: DeviceObj, filteredTemplateRulesTable: TemplateRulesTable): void {
		const epDevice = this.getEpDevicesFromDevices([device])[0]!;
		const quickRulesToBeCreated = getFilterTemplateRules(filteredTemplateRulesTable, device);

		const newTemplateRules = quickRulesToBeCreated.map((quickRule) => {
			const rulesTableEntry = filteredTemplateRulesTable.find((rulesTableEntry) => (rulesTableEntry.id === quickRule.id));
			if (rulesTableEntry === undefined) {
				console.warn("No rule-template found!", quickRule, filteredTemplateRulesTable);
				return undefined;
			}

			const checks: TemplateChecksOrActions<typeof Constants.Rule.ItemsType.Checks> = [];
			const actions: TemplateChecksOrActions<typeof Constants.Rule.ItemsType.Actions> = [];

			// eslint-disable-next-line max-nested-callbacks
			for (const quickRuleParam of quickRule.data.params) {
				const mac = epDevice.id;
				const epId = epDevice.epId;

				const editedRuleJson = structuredClone(quickRuleParam.ruleJson);

				switch (quickRuleParam.category) {
					case RuleCategory.device.id:
					case RuleCategory.duration.id:
					case RuleCategory.temperature.id:
					case RuleCategory.ph.id:
					case RuleCategory.saltConcentration.id:
					case RuleCategory.oydoReductionPotential.id:
					case RuleCategory.totalDissolvedSolids.id:
					case RuleCategory.setpoint.id:
					case RuleCategory.illuminance.id:
					case RuleCategory.humidity.id:
					case RuleCategory.co.id:
					case RuleCategory.co2.id:
					case RuleCategory.voc.id:
					case RuleCategory.mode.id:
						// not implemented
						break;
					case RuleCategory.predefined.id:
						if (quickRuleParam.ruleJson.deviceId === Constants.Rule.VirtualDeviceId.Notification) {
							editedRuleJson.value = i18n.t(`templateRuleTexts.${rulesTableEntry.data.title}`, rulesTableEntry.data.defaultTitle, {});
						}
						break;
					default:
						console.warn("Unsupported rule-category:", quickRuleParam.category);
				}

				for (const key in editedRuleJson) {
					if (editedRuleJson.hasOwnProperty(key)) {
						if (editedRuleJson[key] === "{GWID}") {
							editedRuleJson[key] = epDevice.gwId;
						} else if (editedRuleJson[key] === "{GWMAC}") {
							editedRuleJson[key] = epDevice.gwId.replaceAll(":", "");
						} else if (editedRuleJson[key] === "{MAC}" && mac) {
							editedRuleJson[key] = mac;
						} else if (editedRuleJson[key] === "{EP}" && epId) {
							editedRuleJson[key] = epId;
						}
					}
				}

				if (Object.keys(editedRuleJson).length > 0) {
					switch (quickRuleParam.type) {
						case Constants.Rule.ParamType.Check:
							checks.push(editedRuleJson as TemplateChecksOrActions<typeof Constants.Rule.ItemsType.Checks>[number]);
							break;
						case Constants.Rule.ParamType.Action:
							actions.push(editedRuleJson as TemplateChecksOrActions<typeof Constants.Rule.ItemsType.Actions>[number]);
							break;
						default:
							console.warn("Unknown template-type", quickRuleParam, editedRuleJson);
					}
				}
			}

			checks.push({
				gatewayId: gatewayId,
				deviceId: gatewayId.replaceAll(":", ""),
				endpoint: "00",
				clusterId: "0000",
				attributeId: Constants.Gateway.ReportId.SmartMode,
				templateParamId: Constants.Rule.TemplateParamId.SmartMode,
				templateParamValue: Constants.SmartModes,
				value: mapOrArrayValues(Constants.SmartModes),
			});

			const ruleObject = {
				type: Constants.Rule.Type.Template,
				enabled: true,
				name: i18n.t(`templateRuleTexts.${quickRule.data.title}`, quickRule.data.defaultTitle, {}),
				checks: checks,
				actions: actions,
				templateId: quickRule.id,
				linkId: `@${device.id};${quickRule.id}`,
			} as const satisfies SetOptional<TemplateRule, "id" | "state">;

			return ruleObject;
		}).filter((templateRule) => (templateRule)) as Array<SetOptional<TemplateRule, "id" | "state">>;

		if (newTemplateRules.length > 0) {
			Rules.saveRules(newTemplateRules, (error, msg) => {
				if (!error && msg?.payload.status === "ok") {
					// TODO: show message?
				} else {
					console.error(`"Add quickRules failed for:" ${device.id}, "gatewayid:" ${gatewayId}`);
				}
			});
		}
	}

}

export default (new Devices());
